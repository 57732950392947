<template>
  <b-container class="p-5">
    <b-card class="shadow-sm" border-variant="light">
      <div class="p-4">
        <h1 class="h2 mb-3">{{ post.title }}</h1>
        <b-card-text>
          <b-icon
            icon="calendar"
            variant="secondary"
            class="mr-1"
            font-scale="0.8"
          />
          <span class="font-weight-bold text-secondary small strong">
            {{ getPostDate(post.date) }}
          </span>
        </b-card-text>
        <b-card-text v-html="post.content"> </b-card-text>
      </div>
    </b-card>
    <hr class="my-5" />
    <h3 class="h3 mb-4">Artikel lainnya</h3>
    <b-row>
      <b-col
        v-for="(post, index) in otherPosts.posts"
        :key="`article-${index}`"
        sm="4"
      >
        <article-card
          :id="post.ID"
          :title="post.title"
          :summary="post.excerpt"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import ArticleCard from '../components/ArticleCard.vue'
export default {
  name: 'ArticleDetail',
  components: {
    ArticleCard,
  },

  data() {
    return {
      postDetailUrl:
        'https://public-api.wordpress.com/rest/v1.1/sites/cerdaskeuangan.wordpress.com/posts/' +
        this.$route.params.id,
      post: {},

      postsUrl:
        'https://public-api.wordpress.com/rest/v1.1/sites/cerdaskeuangan.wordpress.com/posts/',
      queryOptions: {
        exclude: this.$route.params.id,
        number: 3,
        page: 1,
        offset: 0,
        order_by: 'date',
        order: 'DESC',
      },
      otherPosts: {},
    }
  },

  methods: {
    async getArticleDetail() {
      await axios
        .get(this.postDetailUrl)
        .then((response) => {
          this.post = response.data
          this.updateStyle()
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async getOtherPosts() {
      await axios
        .get(this.postsUrl, { params: this.queryOptions })
        .then((response) => {
          this.otherPosts = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },

    getPostDate(date) {
      return moment(date).format('Do MMMM YYYY')
    },

    updateStyle() {
      setTimeout(() => {
        const wpCaptions = document.querySelectorAll('.wp-caption')
        wpCaptions.forEach((caption) => {
          if (caption.style.width != '') {
            caption.style.width = '100%'
          }
        })

        const articleImages = document.querySelectorAll('.article-content img')
        articleImages.forEach((img) => {
          if (img.width > 1000) {
            img.classList.add('article-img')
          }
          if (img.height > 1000) {
            img.style.height = '50%'
          }
        })
      }, 100)
    },
  },
  beforeMount() {
    this.getArticleDetail(), this.getOtherPosts()
  },
}
</script>
